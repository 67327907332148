.layout {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.logo {
  position: relative;
  top: 15px;
  padding-right: 10px;
  height: 120px;
  pointer-events: none;
}

.App {
  width: 100%;
  text-align: center;
}

.wrap-container {
  justify-content: center;
  text-align: center;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

a {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 90%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
}

.styled-table th,
.styled-table td {
    padding: 12px 6px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.bank-image {
  width: 100px;
  max-height: 40px;
}

.ts-wrapper {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  left: 10%;
  width: 80%;
}

.last-update-text {
  font-size: 12px;
  font-family: helvetica;
  font-size: 600;
  color: #ada8a8;
  padding: 15px;
}