
.card-container {
    padding: 15px;
    color: #fff;
    border: 1px solid lightgray;
    border-radius: 15px;
    width: 280px;
    margin: 10px;
}

.card-container:hover {
    color: lightgreen;
    cursor: pointer;
    opacity: 0.8;
}

.card-title {
    color: #fff;
    font-family: helvetica;
    padding-bottom: 5px;
}

.card-value {
    font-size: 16px;
}

.card-last-update {
    font-size: 12px;
    color: #fff;
    padding-bottom: 10px;
}

.card-img {
    width: 200px;
}

.indicator {
    color: lightgray;
}

.separator {
    position: relative;
    width: 98% !important;
    margin: 60px !important;
    border-bottom: 1px solid lightgray;
}